.sidebar {
  width: 25%;
  top: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 2;
  width: 25%;
  background-color: rgba(145,13,13,.8);
  color: #fff;
  text-align: center;
  text-transform: uppercase;
}

.sidebar .profile-photo img {
  width: 152px;
  height: 140px;
  border-radius: 50%;
}

.sidebar .profile-photo {
  margin-top: 50px;
}

.sidebar .profile-content {
  margin-top: 20px;
  text-decoration: none;
}

.sidebar .profile-content h1 {
  font-size: 25px;
}

.sidebar .profile-content h2 {
  font-size: 17px;
  font-weight: 250;
}

.sidebar .navigation {
  margin-top: 100px;
}

.sidebar .navigation a {
  display: block;
  text-decoration: none;
  font-weight: 300;
  color: #fff;
  text-align: left;
  padding-left: 30px;
  padding-top: 20px;
}

.sidebar .navigation a:hover {
  font-weight: 500;
}

.sidebar .get-in-touch {
  text-align: left;
  padding-left: 30px;
  margin-top: 100px;
}

.sidebar .get-in-touch a {
  text-decoration: none;
  color: #fff
}

.sidebar .get-in-touch .icon {
  padding-right: 15px;
  font-size: 20px;
}

.info-page {
  padding-left: 27%;
  z-index: 3;
  background-color: white;
  text-align: center;

  text-align: left;
  font-family: sans-serif;
}

.info-page h3 {
  color: #BB1E1E;
  text-transform: uppercase;
}

.info-card {
  padding-top: 60px;
}
.info-details {
  border-left: solid thin grey;
  font-size: 18px;
  font-weight: 300
}

.info-details li {
  margin-bottom: 10px;
  list-style: square;
}

.detail-text {
  align-self: center;
  
}

.carousel {
  width: 100%;
  border: solid thin lightgrey;
  height: 370px;
  text-align: center;
}



.info-details img {
  padding: 10px;
}

.internship {
  padding-top: 20px;
}




